import React from "react"
import {useNavigate} from 'react-router-dom'
import CustomCarousel from "../../components/Carousel"
import { RoutePaths } from "../../constant";
import ProductCard from "../../components/ProductCard";
import Button from "../../components/button";

import "./../../common/css/global.css";
import "./home.css"

const Home = ()=> {
    const navigate = useNavigate();
    const navigateToServices = () => {
        navigate(RoutePaths.services);
      };
    return (
        <div>
            <CustomCarousel />
            <div className="service-component">
                <h2 className="component-heading center">Analyze. Collaborate. Innovate.</h2>
                <div className="heading-dash"/>
                <div className="service-content">
                <p className="content-text">Techossy Solutions LLC, a global professional services firm, dedicated to helping our clients succeed in the digital economy. We are experts in cloud computing, big data, artificial intelligence and emerging technologies. Our internal databases provide intelligence on global drugs, clinical trials, companies, drug prices, drug sales & estimates, patent expiry, manufacturing, generics, drug master files, technology, contacts, future events and deals. Techossy Solutions resources expertise on Data Architecture, Big Data Architecture, Data migration, ETL development, Hybrid Cloud development, SAP ERP, Drug Database Analytics, Market Intelligence, UX/UI Development. We provide IT development strategies and solutions for small scale to large scale companies.</p>
                </div>
            </div>
            <div className="tech-list-component">
                <ProductCard image="images/advanced-data-analytics.svg" title="Advanced data analytics"
                showHoverAnimation
                hoverImage="images/hover-advanced-data-analytics.svg"
                hoverText="Advanced Analytics is the autonomous or semi-autonomous examination of data or content using sophisticated techniques and tools."
                />
                <ProductCard image="images/big-data.png" title="Big Data/Data Science"
                showHoverAnimation
                hoverImage="images/hover-big-data.png"
                hoverText="Data has become the most critical factor in business today. As a result, different technologies and systems have been invented to process."/>
                <ProductCard image="images/data-migration.svg" title="Data Migration"
                showHoverAnimation
                hoverImage="images/hover-data-migration.png"
                hoverText="Data migration is the transfer of the existing historical data to new storage, system, or file format."/>
                <ProductCard image="images/drug-database-analytics.png" title="Drug Database Analytics"
                showHoverAnimation
                hoverImage="images/hover-drug-database-analytics.png"
                hoverText="Techossy foresees customer success through their products and services along with other biopharma players."/>
                <ProductCard image="images/ux-design.svg" title="Hybrid Cloud Computing"
                showHoverAnimation
                hoverImage="images/hover-ux-design.svg"
                hoverText="Hybrid cloud refers to a mixed computing, storage, and services environment made up of on-premises infrastructure."/>
                <ProductCard image="images/sap.svg" title="SAP Technical & Functional"
                showHoverAnimation
                hoverImage="images/hover-sap.png"
                hoverText="SAP Technical Modules – Are modules used on the back-end of an SAP landscape to help maintain and fine-tune your environment, build applications."/>
            </div>
            <div className="service-component">
                <h2 className="component-heading center">Our Expertise</h2>
                <div className="heading-dash"/>
                <div className="service-content">
                    <p className="content-text">With our deep expertise and experience team, we have created Road Maps, Build Solutions and Delivered Value across Organizations. Here is the list of tools we have acquired and equipped with</p>
                </div>
                <div className="expert-products">
                    <div className="expert-prduct-image">
                    <img src="images/dataiku.svg" alt="logo"  />
                    </div>
                    <div className="expert-prduct-image">
                    <img src="images/alation.svg" alt="logo"/>
                    </div>
                    <div className="expert-prduct-image">
                    <img src="images/informatica.svg" alt="logo" />
                    </div>
                    <div className="expert-prduct-image">
                    <img src="images/aws.svg" alt="logo" />
                    </div>
                    <div className="expert-prduct-image">
                    <img src="images/azure.svg" alt="logo" />
                    </div>

                    <div className="expert-prduct-image">
                    <img src="images/hadoop.svg" alt="logo"/>
                    </div>
                    <div className="expert-prduct-image">
                    <img src="images/googlecloud.svg" alt="logo" />
                    </div>
                    <div className="expert-prduct-image">
                    <img src="images/java.svg" alt="logo" />
                    </div>
                    <div className="expert-prduct-image">
                    <img src="images/salesforce.svg" alt="logo"/>
                    </div>
                    <div className="expert-prduct-image">
                    <img src="images/saplogo.svg" alt="logo"/>
                    </div>
                    <div className="expert-prduct-image mt30">
                    <img src="images/snowflake.svg" alt="logo" />
                    </div>
                    <div className="expert-prduct-image mt30">
                    <img src="images/uipath.svg" alt="logo" />
                    </div>
                    <div className="expert-prduct-image mt30">
                    <img src="images/talend.svg" alt="logo" />
                    </div>
                </div>
                
            </div>
            <div className="service-component">
                    <h2 className="component-heading center">Industries We Serve</h2>
                    <div className="heading-dash"/>
                    <div className="service-content">
                        <p className="content-text">Techossy works with multiple Business segments where Data is involved. We love playing with Data and that’s what determines our vision. </p>
                    </div>
                    
            </div>
            <div className="data-component">
                    <div className="data-number">
                        <p className="count">350+</p>
                        <p className="data-text">Satisfaction Client</p>
                    </div>
                    <div className="data-number">
                        <p className="count">85+</p>
                        <p className="data-text">Projects Done</p>
                    </div>
                    <div className="data-number">
                        <p className="count">500+</p>
                        <p className="data-text">Professional Worker</p>
                    </div>
                    <div className="data-number border0">
                        <p className="count">725+</p>
                        <p className="data-text">Best choice developer</p>
                    </div>
            </div>

            <div className="industry-component">
            <p className="industry-component-text">With our deep expertise and experienced Team, we have created Road Maps, Build Solutions and Delivered Value across Organizations. Here is the list of tools we have acquired and equipped with...</p>

                <div className="industry-component-card-section">
                <div className="industry-component-card">
                    <img src="images/industry-4.gif" alt="logo" />
                    <div className="industry-component-card-divider"></div>
                    <div className="industry-component-card-text">Travel And Tourism</div>
                </div>
                <div className="industry-component-card">
                    <img src="images/industry-1.gif" alt="logo" />
                    <div className="industry-component-card-divider"></div>
                    <div className="industry-component-card-text">Financial Services</div>
                </div>
                <div className="industry-component-card">
                    <img src="images/industry-3.gif" alt="logo" />
                    <div className="industry-component-card-divider"></div>
                    <div className="industry-component-card-text">Insurance Healthcare</div>
                </div>
                <div className="industry-component-card">
                    <img src="images/industry-6.gif" alt="logo" />
                    <div className="industry-component-card-divider"></div>
                    <div className="industry-component-card-text">Tele Communications</div>
                </div>
                <div className="industry-component-card">
                    <img src="images/industry-5.gif" alt="logo" />
                    <div className="industry-component-card-divider"></div>
                    <div className="industry-component-card-text">Manufacturing</div>
                </div>
                <div className="industry-component-card">
                    <img src="images/industry-2.gif" alt="logo" />
                    <div className="industry-component-card-divider"></div>
                    <div className="industry-component-card-text">E-Commerce & Retail</div>
                </div>
                </div>
            </div>
            <div className="different-component">
                <img src="images/different.png" alt="logo" />
                <div className="different-component-content">
                    <h2 className="component-heading center white">How We're Different</h2>
                    <div className="heading-dash"/>
                    <div className="service-content different-comp-section">
                        <p className="content-text white show-mobile">Our business domain knowledge, proven methodologies, and skilled software developers’ expertise help us excel in delivering tailor-made solutions for various customer needs.</p>
                        <p className="content-text white hide-mobile">Our business domain knowledge, proven methodologies, and skilled software developers’ expertise help us excel in delivering tailor-made solutions for various customer needs. We have worked on end-to-end outsourced product development for applications such as ERP, CRM, CMS, asset management, eCommerce, ad serving, and much more.</p>
                        {/* <p className="content-text white hide-mobile">From small startups to enterprises, product companies to non-profit organizations, online retailers to educational establishments, – we cater to them all for their diverse technology requirements.</p>
                        <p className="content-text white hide-mobile">We’ve mastered a professional, proactive, and transparent approach to service delivery, making us a top IT firm for worldwide clients.</p> */}
                        <div className="center diff-btn-section">
                        <Button 
                    buttonType="secondary"
                    buttonText="Learn More"
                    onClickHandler={navigateToServices}/>
                            </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Home;